/** 用户信息 */
export const user = [
  {
    name: 'getCurrentUserInfo', // 用户个人信息
    isMock: false,
  },
  {
    name: 'getCurrentUserInfo', // 用户个人信息
    isMock: false,
  },
  {
    name: 'getAvailableBalance', // 获取可用永久积分
    isMock: false,
  },
]
