import { Toast } from 'antd-mobile'

/** 判断设备类型 */
const deviceType = (reg: any) => {
  // for ssr
  if (typeof navigator === 'undefined') {
    return false
  }
  const ua = navigator.userAgent.toLowerCase()

  return reg.test(ua)
}

export const isIOS = () => {
  return deviceType(/(iphone|ipad|ipod)/i)
}

export const isAndroid = () => {
  return deviceType(/(Android|android)/i)
}

export const isWeixin = () => {
  return deviceType(/MicroMessenger/i)
}

export const isQQBrowser = () => {
  return deviceType(/(qqbrowser)\/([\d.]+)/i)
}

/** UC浏览器 */
export const isUCBrowser = () => {
  return deviceType(/(ucbrowser)\/([\d.]+)/i)
}

/** 夸克浏览器 */
export const isQuarkBrowser = () => {
  return deviceType(/(quark)\/([\d.]+)/i)
}

/** 判断是否支持图片下载 */
export const isSupportDownload = () => {
  if (isQQBrowser() || isUCBrowser() || isQuarkBrowser()) {
    return false
  }
  return true
}

/** 判断是否支持图片长按保存 */
export const isSupportSave = () => {
  if ((isAndroid() && isUCBrowser()) || (isAndroid() && isQuarkBrowser())) {
    return false
  }
  return true
}
