import { useMemo } from 'react'
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  ApolloLink,
  ServerError,
  NormalizedCacheObject,
} from '@apollo/client'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { getAuth, randomRange, getCookies } from '~/utils'
import { getMockUri } from '~/utils/mockUtil'
import { onError } from '@apollo/link-error'
import { IncomingHttpHeaders } from 'http'
import type { AppProps } from 'next/app'
import { GetServerSidePropsContext } from 'next'
import { SystemCode } from '../types'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

const uri = process.env.NEXT_PUBLIC_GRAPHQL_API

function createApolloClient(context: GetServerSidePropsContext | null = null) {
  const httpLink = new HttpLink({
    uri,
    credentials: 'same-origin',
  })

  const authLink = new ApolloLink((operation, forward) => {
    const cookies =
      typeof window === 'undefined'
        ? context?.req?.cookies || {}
        : getCookies(document.cookie)
    operation.setContext(({ headers }: { headers: IncomingHttpHeaders }) => {
      const tempHeaders: IncomingHttpHeaders = {
        from: 'web',
        'app-code': 'trade',
        device_id: randomRange(20, 20),
        authorization: getAuth(),
        system_code:
          operation.operationName === 'getMysteryBanner'
            ? SystemCode.COPYRIGHT
            : SystemCode.COPYRIGHT_SHOP,
        ...headers,
      }

      if (cookies['gate-token'] && cookies['gate-token'] !== 'null') {
        tempHeaders['gate-token'] = cookies['gate-token']
      }

      return {
        uri:
          getMockUri(uri, operation.operationName) +
          '/' +
          operation.operationName,
        headers: tempHeaders,
      }
    })

    return forward(operation)
  })

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const originResp = operation.getContext()['response'] as Response
    const traceID = originResp?.headers?.get('trace_id')
    if (originResp) {
      console.log('[TRACE_ID]: ', traceID)
    }

    const errMsg =
      graphQLErrors?.[0]?.extensions?.message || graphQLErrors?.[0].message

    // message.error(errMsg)
    console.error(errMsg)

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      })

      // @ts-ignore
      graphQLErrors.push({ message: 'traceid:' + traceID })
    }

    if (networkError) {
      const code = (networkError as ServerError).result?.code

      console.log(`[Network error]: ${networkError}`)
      console.log(`[Network error code]: ${code}`)
    }
  })

  const link = from([errorLink, authLink, httpLink])

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache: new InMemoryCache(),
  })
}

type InitialState = NormalizedCacheObject | undefined

interface IInitializeApollo {
  context?: GetServerSidePropsContext | null
  initialState?: InitialState | null
}

export const initializeApollo = (
  { context, initialState }: IInitializeApollo = {
    context: null,
    initialState: null,
  }
) => {
  const _apolloClient = apolloClient ?? createApolloClient(context)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export const addApolloState = (
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: AppProps['pageProps']
) => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export const useApollo = (pageProps: AppProps['pageProps']) => {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(
    () => initializeApollo({ initialState: state }),
    [state]
  )
  return store
}
