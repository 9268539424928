/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountGroupTypeEnum {
  AI_PRO_ACCOUNT = "AI_PRO_ACCOUNT",
  BALANCE_WALLET = "BALANCE_WALLET",
  BLANK_PASS = "BLANK_PASS",
  BRONZE_CARD = "BRONZE_CARD",
  GOLD_CARD = "GOLD_CARD",
  HS_ACCOUNT = "HS_ACCOUNT",
  ORIGIN_CARD = "ORIGIN_CARD",
  PLATINUM_CARD = "PLATINUM_CARD",
  POINT_WALLET = "POINT_WALLET",
  SILVER_CARD = "SILVER_CARD",
  TEMPORARY_ACCOUNT = "TEMPORARY_ACCOUNT",
  WJ_AI_MEMBER = "WJ_AI_MEMBER",
  WJ_AI_PRO_MEMBER = "WJ_AI_PRO_MEMBER",
  XY_POINT = "XY_POINT",
}

export enum ActionType {
  CANCEL = "CANCEL",
  CONFIRM = "CONFIRM",
}

/**
 * 受影响对象类型
 */
export enum AffectedType {
  ACTIVITY = "ACTIVITY",
  AI_ARTWORK = "AI_ARTWORK",
  AI_COLLECTION = "AI_COLLECTION",
  AI_COMIC = "AI_COMIC",
  AI_COMPETITION = "AI_COMPETITION",
  ANNUAL_REPORT = "ANNUAL_REPORT",
  ARTWORK = "ARTWORK",
  BOUNTY = "BOUNTY",
  CHANNEL = "CHANNEL",
  COMMENT = "COMMENT",
  EXTERNAL_AI_ARTWORK = "EXTERNAL_AI_ARTWORK",
  NOTE = "NOTE",
  STYLE_MODEL = "STYLE_MODEL",
  VIDEO = "VIDEO",
  VOTE = "VOTE",
  WORKFLOW = "WORKFLOW",
}

/**
 * 图片状态
 */
export enum AvatarArtworkStatus {
  FAIL = "FAIL",
  ING = "ING",
  SUCCESS = "SUCCESS",
}

/**
 * 业务类型
 */
export enum AvatarServiceType {
  CAMERA_LITE = "CAMERA_LITE",
  MINI_PROGRAM = "MINI_PROGRAM",
  PERSONALITY_CAMERA = "PERSONALITY_CAMERA",
  SINGLE_IMAGE = "SINGLE_IMAGE",
}

/**
 * 化身状态
 */
export enum AvatarStatus {
  CREATED = "CREATED",
  GENERATED = "GENERATED",
  QUEUE = "QUEUE",
  QUEUE_TIME_OUT = "QUEUE_TIME_OUT",
  TRAINING = "TRAINING",
  TRAINING_FAIL = "TRAINING_FAIL",
}

export enum AvatarTrainBaseModelEnum {
  CHILLOUTMIX_NI_PRUNED_FP32_FIX = "CHILLOUTMIX_NI_PRUNED_FP32_FIX",
}

/**
 * 化身性别
 */
export enum GenderEnum {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

/**
 * 手机验证码类型
 */
export enum MobileVerifyType {
  SMS = "SMS",
  VOICE = "VOICE",
}

/**
 * 不同用途的bucket枚举
 */
export enum OssBucket {
  NEWS = "NEWS",
  TRADE_PRIVATE = "TRADE_PRIVATE",
  TRADE_PROTECT = "TRADE_PROTECT",
  TRADE_RESOURCE = "TRADE_RESOURCE",
}

export enum PayChannelEnum {
  ALI_PAY = "ALI_PAY",
  CASHIER_PAY = "CASHIER_PAY",
  IAP = "IAP",
  UNION_PAY = "UNION_PAY",
  WX_PAY = "WX_PAY",
}

export enum PayScene {
  ACCOUNT_SPLITTING = "ACCOUNT_SPLITTING",
  ORIGIN = "ORIGIN",
  SHEN_YIN = "SHEN_YIN",
  SHEN_YIN_WX = "SHEN_YIN_WX",
}

/**
 * 支付类型
 */
export enum PaymentTypeEnum {
  DURATION = "DURATION",
  INTEGRAL = "INTEGRAL",
}

export enum Scene {
  AI_ARTWORK = "AI_ARTWORK",
  AI_ARTWORK_COLLECTION = "AI_ARTWORK_COLLECTION",
  APP_SHARE_AI_ARTWORK_TITLE = "APP_SHARE_AI_ARTWORK_TITLE",
  AVATAR_TRAIN = "AVATAR_TRAIN",
  LORA_TRAIN_DATA_SET_IMAGE = "LORA_TRAIN_DATA_SET_IMAGE",
  SQUARE_EXTERNAL_VIDEO = "SQUARE_EXTERNAL_VIDEO",
  SQUARE_POST = "SQUARE_POST",
  VIDEO_GENRATE_VIDEO_UPLOAD_VIDEO = "VIDEO_GENRATE_VIDEO_UPLOAD_VIDEO",
}

export enum SocialViewType {
  AI_CREATION = "AI_CREATION",
  COMMON = "COMMON",
  COMPETITION = "COMPETITION",
  MODEL = "MODEL",
  SQUARE = "SQUARE",
  SQUARE_VOTE = "SQUARE_VOTE",
}

export enum ThirdAccountType {
  APPLE = "APPLE",
  SAN_WEI_JIA = "SAN_WEI_JIA",
  WECHAT = "WECHAT",
  WECHAT_MINI_PROGRAM = "WECHAT_MINI_PROGRAM",
}

/**
 * 发送验证码的业务场景
 */
export enum VerifyCodeScene {
  ACCOUNT_BALANCE_TRANS = "ACCOUNT_BALANCE_TRANS",
  BIND_EMAIL = "BIND_EMAIL",
  BIND_MOBILE = "BIND_MOBILE",
  CHANGE_PWD = "CHANGE_PWD",
  LOGIN_REGISTER = "LOGIN_REGISTER",
  LOGOFF = "LOGOFF",
  RECORD_PARK_USER = "RECORD_PARK_USER",
}

export interface ArtworkTemplateParam {
  number?: number | null;
  templateId: any;
}

/**
 * 图片分页查询入参
 */
export interface AvatarArtworkQueryInput {
  artworkId?: any[] | null;
  avatarId?: any[] | null;
  themeId?: any[] | null;
  themeName?: string[] | null;
}

/**
 * 化身创建
 */
export interface AvatarCreateInput {
  avatarTrainParam?: AvatarTrainParamInput | null;
  gender?: GenderEnum | null;
  name?: string | null;
  serviceType?: AvatarServiceType | null;
  trainImage?: (TrainImageInput | null)[] | null;
}

export interface AvatarQueryInput {
  filterStatus?: (AvatarStatus | null)[] | null;
  serviceType?: AvatarServiceType | null;
  serviceTypes?: (AvatarServiceType | null)[] | null;
}

export interface AvatarTrainParamInput {
  baseModelName?: AvatarTrainBaseModelEnum | null;
  dataLoaderNumWorks?: number | null;
  gradientAccumulationSteps?: number | null;
  learningRate?: any | null;
  maxStepsPerPhotos?: number | null;
  maxTrainSteps?: number | null;
  networkAlpha?: number | null;
  rankLora?: number | null;
  resolution?: number | null;
  trainBatchSize?: number | null;
  validationAndSaveSteps?: number | null;
}

/**
 * 行为校验参数
 */
export interface BehaviorVerifyParam {
  authenticate: string;
  token: string;
}

/**
 * 化身图片创建入参
 */
export interface CreateAvatarArtworkParam {
  avatarId: any;
  payType?: PaymentTypeEnum | null;
  prompt?: string | null;
  size?: string | null;
  templates?: ArtworkTemplateParam[] | null;
}

/**
 * 创建菜单查询参数
 */
export interface CreateMenuQueryInput {
  avatarId?: any | null;
}

/**
 * 社交动作入参
 */
export interface GeneralSocialActionInput {
  actionType?: ActionType | null;
  affectedType?: AffectedType | null;
  id?: any | null;
  modelKey?: string | null;
  viewType?: SocialViewType | null;
}

/**
 * 图片
 */
export interface Image {
  url: string;
}

export interface ImageCheckInput {
  imageUrl?: string | null;
  lightweight?: boolean | null;
}

export interface ImageDetectionInput {
  image: Image;
  needSyncReturn?: boolean | null;
  scene: Scene;
}

export interface PayTypeInput {
  payChannel?: number | null;
  payProvider?: number | null;
  payType?: number | null;
  returnUrl?: string | null;
}

/**
 * 充值input
 */
export interface RechargeInput {
  num?: number | null;
  payTypeInfo?: PayTypeInput | null;
  rechargeAmount?: any | null;
  rechargeType?: AccountGroupTypeEnum | null;
}

export interface ReportPageViewInput {
  channelId: string;
  page: string;
}

export interface TrainImageInput {
  imageDetectionId?: any | null;
  imageUrl: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
