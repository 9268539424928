import { useMemo } from 'react'
import {
  applySnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from 'mobx-state-tree'
import { UserStore, IUserStore } from './UserStore'

const Modal = types
  .model({
    login: types.boolean,
  })
  .actions(self => {
    const setLoginVisible = (status: boolean) => {
      self.login = status
    }

    return {
      setLoginVisible,
    }
  })

const Store = types
  .model({
    token: types.maybeNull(types.string),
    userinfo: types.maybeNull(UserStore),
    modal: types.maybeNull(Modal),
  })
  .actions(self => {
    const setToken = (token: string | null) => {
      self.token = token
    }
    const setUserinfo = (userinfo: IUserStore | null) => {
      self.userinfo = userinfo
    }

    return {
      setToken,
      setUserinfo,
    }
  })

export type IStore = Instance<typeof Store>
export type IStoreSnapshotIn = SnapshotIn<typeof Store>
export type IStoreSnapshotOut = SnapshotOut<typeof Store>

let store: IStore

export function initializeStore(snapshot: any = null) {
  const _store = store ?? Store.create()

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.tsx` and `pages/ssr.tsx` for more details
  if (snapshot) {
    applySnapshot(_store, snapshot)
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return store
}

export function useInitStore(initialState: any) {
  const store = useMemo(() => initializeStore(initialState), [initialState])
  return store
}
