import Compressor from 'compressorjs'

export function getBase64(
  img: File | Blob | undefined,
  callback: (url: string) => void
) {
  if (img) {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      if (reader.result && typeof reader.result === 'string') {
        callback(reader.result)
      }
    })
    reader.readAsDataURL(img)
  }
}

export function saveAs(href: string | Blob, fileName: string) {
  const isBlob = href instanceof Blob
  const aLink = document.createElement('a')
  aLink.href = isBlob ? window.URL.createObjectURL(href) : href
  aLink.download = fileName
  aLink.click()
  if (isBlob) setTimeout(() => URL.revokeObjectURL(aLink.href), 100)
}

// 支持cdn文件下载
export function xhrDownload(
  url: string,
  fileName?: string,
  fileType: string = 'image'
) {
  return new Promise((reslove, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'

    xhr.onload = () => {
      if (xhr.status === 200) {
        const defaultName = url.substring(url.lastIndexOf('/') + 1)
        saveAs(xhr.response, decodeURIComponent(fileName || defaultName))
        reslove(fileName)
      }
    }
    xhr.onerror = err => reject(err)

    xhr.open('get', url, true)

    if (fileType === 'image') {
      // 为了排除webp
      xhr.setRequestHeader('accept', 'image/jpeg, image/png')
      // 不使用缓存
      xhr.setRequestHeader('If-Modified-Since', '0')
    }

    xhr.send()
  })
}

/** 获取图片宽高 */
export const getImageSizes = (
  img: File | Blob,
  callback: ({ width, height }: { width: number; height: number }) => void
) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => {
    if (reader.result && typeof reader.result === 'string') {
      const image = new Image()
      image.onload = () => {
        callback({
          width: image.width,
          height: image.height,
        })
      }
      image.src = reader.result
    }
  })
  reader.readAsDataURL(img)
}

export interface CompressorOptionsProps {
  quality?: number
  maxWidth?: number
  convertSize?: number
}

export const COMPRESSOR_OPTIONS = {
  quality: 0.8,
  maxWidth: 2048,
  convertSize: 1000000, // 1MB
}

/** 压缩图片 */
export const compressorImage = (
  file: File,
  options?: CompressorOptionsProps
) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: options?.quality || COMPRESSOR_OPTIONS.quality,
      maxWidth: options?.maxWidth || COMPRESSOR_OPTIONS.maxWidth,
      convertSize: options?.convertSize || COMPRESSOR_OPTIONS.convertSize, // 超过图片大小限制会转成jpg
      success(result) {
        const newFile = new File([result], result.name, { type: result.type })
        resolve(newFile)
      },
      error(err) {
        reject(err)
        console.log(err && err.message)
      },
    })
  })
}
