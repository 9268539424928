import CryptoJS from 'crypto-js'

export function encrypt(plaintText: string, cryptojsKey: string) {
  const options = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }
  const key = CryptoJS.enc.Utf8.parse(cryptojsKey)
  const encryptedData = CryptoJS.AES.encrypt(plaintText, key, options)
  const encryptedBase64Str = encryptedData
    .toString()
    .replace(/\//g, '_')
    .replace(/\+/g, '-')

  return encryptedBase64Str
}

export function decrypt(encryptedBase64Str: string, cryptojsKey: string) {
  const vals = encryptedBase64Str.replace(/\-/g, '+').replace(/_/g, '/')
  const options = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }
  const key = CryptoJS.enc.Utf8.parse(cryptojsKey)
  const decryptedData = CryptoJS.AES.decrypt(vals, key, options)
  const decryptedStr = CryptoJS.enc.Utf8.stringify(decryptedData)
  return decryptedStr
}

export const getAuth = () => {
  const CRYPTOJSKEY = 'WTAHAPPYACTIVITY'

  let timestamp = Math.floor(new Date().getTime() / 1000).toString()
  let plaintText = JSON.stringify({
    appId: '1',
    timestamp,
    serverCode: '0',
  })

  return JSON.stringify({
    secretKeyVersion: 1,
    sign: encrypt(plaintText, CRYPTOJSKEY),
  })
}
