/** 登录注册 */
export const login = [
  {
    name: 'getMobileCode', // 获取验证码
    isMock: false,
  },
  {
    name: 'loginByMobile', // 登录/注册
    isMock: false,
  },
  {
    name: 'loginByMobilePwd', // 密码登录
    isMock: false,
  },
  {
    name: 'setPwdByMobile', // 重置密码
    isMock: false,
  },
]
