/** mock项目地址 */
export const mockHost =
  'https://data.flutterweb.cn/mock/2258/one-graph-wujiebantu/graphql'

import { login } from './login'
import { user } from './user'
import { avatar } from './avatar'

/** 启用mock的operationName */
export const mockKeys = [...login, ...user, ...avatar]
