import { types, Instance } from 'mobx-state-tree'
const UserBase = types.model({
  uid: types.number,
  mobile: types.maybeNull(types.string),
})
const IntegralWallet = types
  .model({
    availableBalance: types.maybeNull(types.number),
  })
  .actions(self => {
    const resetAvailableBalance = (value: number) => {
      self.availableBalance = value
    }
    return {
      resetAvailableBalance,
    }
  })

export const UserStore = types
  .model({
    base: types.maybeNull(UserBase),
    integralWalletBalance: types.maybeNull(IntegralWallet),
  })
  .actions(self => {
    const setBaseInfo = (base: IUserBase) => {
      self.base = base
    }
    return {
      setBaseInfo,
    }
  })
export type IIntegralWallet = Instance<typeof IntegralWallet>
export type IUserBase = Instance<typeof UserBase>
export type IUserStore = Instance<typeof UserStore>
