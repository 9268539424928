import dayjs from 'dayjs'
import accurateCalc from 'accurate-core'
import { IMAGE_CDN } from '~/configs'
import { round } from 'lodash'

const isProd = process.env.NEXT_PUBLIC_NODE_ENV === 'production'

export const toYMDHms = (timestamp: number) => {
  return dayjs(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')
}

export const toYMDHm = (timestamp: number) => {
  return dayjs(timestamp * 1000).format('YYYY-MM-DD HH:mm')
}

export const toYMD = (timestamp: number) => {
  return dayjs(timestamp * 1000).format('YYYY-MM-DD')
}

export const toHm = (timestamp: number) => {
  return dayjs(timestamp * 1000).format('HH:mm')
}

export const toFormatYMDHm = (timestamp: number, format: string) => {
  return dayjs(timestamp * 1000).format(format)
}

export const formatTime = (timestamp: number, format: string) => {
  return dayjs(timestamp * 1000).format(format)
}

export const FenToYuan = (num: number | string | undefined | null) => {
  return Number.parseFloat(accurateCalc.divide(Number(num), 100).toFixed(2))
}

//1.1*100 = 110.000001等精度问题
export const YuanToFen = (num: number | string | undefined | null) => {
  return accurateCalc.mul(Number(num), 100)
}

export const showNickName = (args: string | undefined | null) => {
  if (args === null || args === undefined) return ''

  if (args.length > 2) {
    return args?.substring(0, 1) + '*' + args?.substring(args.length - 1)
  } else {
    return args?.substring(0, 1) + '*'
  }
}

export const showMobile = (args: number | string | undefined | null) => {
  return args?.toString().replace(/^(.{3})(?:\w+)(.{4})$/, '$1****$2')
}

export const showRealName = (args: string | undefined | null) => {
  if (args === null || args === undefined) return ''

  return (
    args?.substring(0, 1) + args?.substring(1, args?.length).replace(/./g, '*')
  )
}

export const showIdCard = (args: number | string | undefined | null) => {
  return args?.toString().replace(/^(.{2})(?:\w+)(.{2})$/, '$1**************$2')
}

export const showChainHash = (args: string | undefined | null) => {
  return args?.toString().replace(/^(.{12})(?:\w+)(.{8})$/, '$1...$2')
}

export const numThousandSplit = (value: number | string) => {
  if (isNaN(Number(value)) || !value) return '0'
  value = value.toString()
  const format = /(?!\b)(?=(\d{3})+$)/g
  const arr = value.split('.')
  arr[0] = arr[0].replace(format, ',')
  value = arr.join('.')
  return value
}

export const hexToRgba = (hex: string, opacity: number = 1) => {
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  let color = hex.toLowerCase()
  if (reg.test(color)) {
    if (color.length === 4) {
      let colorNew = '#'
      for (let i = 1; i < 4; i++) {
        colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1))
      }
      color = colorNew
    }
    const colorChange = []
    for (let i = 1; i < 7; i += 2) {
      colorChange.push(parseInt(`0x${color.slice(i, i + 2)}`, 16))
    }
    return `rgba(${colorChange.join(',')},${opacity})`
  }
  return color
}

// 缩略图
const toViewImage = (url: string, width: number) => {
  return `${url}?imageView2/2/w/${width}/q/75`
}

// 废弃，请使用to400Image
export const toXSmallImage = (url: string) => {
  return toViewImage(url, 800)
}

// 废弃，请使用to1200Image
export const toSmallImage = (url: string) => {
  return toViewImage(url, 1200)
}

export const to200Image = (url: string) => {
  return toViewImage(url, 200)
}

export const to400Image = (url: string) => {
  return toViewImage(url, 400)
}

export const to800Image = (url: string) => {
  return toViewImage(url, 800)
}

export const to1200Image = (url: string) => {
  return toViewImage(url, 1200)
}

// 带水印的图
export const toMarkImage = (url: string) => {
  return `${url}?imageView2/2/w/1200/q/75|watermark/1/image/aHR0cHM6Ly9kYXRhLnd1amllYmFudHUuY29tL2xvZ28lRTYlQjAlQjQlRTUlOEQlQjAlMjAyLnBuZw==/dissolve/100/gravity/SouthWest/wst/1/ws/0.083`
}

// 图片用cdn
export const toCDNImage = (url: string) => {
  return isProd ? IMAGE_CDN + url : url
}

export const toHtml = (content: string) => {
  return content.replace(/\n/g, '<br/>')
}

/** 4个空格为换行 */
export const toNewLine = (content: string) => {
  return content.replace(/    /g, '<br/>')
}

export const blobToArrayBuffer = async (blob: Blob) => {
  if ('arrayBuffer' in blob) return await blob.arrayBuffer()

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = event => resolve(event?.target?.result)
    reader.onerror = () => reject()
    reader.readAsArrayBuffer(blob)
  })
}

export const RateToTen = (num: number | string | undefined | null) => {
  //小于等于10的数字，实际是小数的折扣，需要/10
  if ((!num || Number(num) % 10 !== 0) && Number(num) > 10) return num
  return accurateCalc.divide(Number(num), 10)
}

/**
 * 倒计时时间戳转换为时间格式
 * @param time 时间戳
 * @param wantObj 返回数据是否是对象
 * @returns 时间格式 or 包含天时分秒的对象
 */
export const genCountDown = (time: number): string => {
  // @ts-ignore
  const duration = dayjs.duration(time * 1000)
  const [days, hours, minutes, seconds] = [
    duration.asDays(),
    duration.hours(),
    duration.minutes(),
    duration.seconds(),
  ]

  return `${Math.floor(days) ? `${Math.floor(days)} 天 ` : ''}${
    hours <= 9 ? `0${hours}` : hours
  }:${minutes <= 9 ? `0${minutes}` : minutes}:${
    seconds <= 9 ? `0${seconds}` : seconds
  }`
}

export const getCountDownObj = (
  time: number
): {
  days: string | number
  hours: string | number
  minutes: string | number
  seconds: string | number
} => {
  const duration = dayjs.duration(time * 1000)
  const [days, hours, minutes, seconds] = [
    duration.asDays(),
    duration.hours(),
    duration.minutes(),
    duration.seconds(),
  ]
  return {
    days: Math.floor(days),
    hours: hours <= 9 ? `0${hours}` : hours,
    minutes: minutes <= 9 ? `0${minutes}` : minutes,
    seconds: seconds <= 9 ? `0${seconds}` : seconds,
  }
}

export function timeAgo(time: number) {
  const timestamp = /^[\d]+$/.test(time + '')

  if (!timestamp) {
    time = new Date(time).getTime() / 1000
  }
  const between = Date.now() / 1000 - time
  if (between < 60) {
    return '刚刚'
  } else if (between < 3600) {
    return `${~~(between / 60)} 分钟前`
  } else if (between < 86400) {
    return `${~~(between / 3600)} 小时前`
  } else if (between < 172800) {
    return `${~~(between / 86400)} 天前`
  } else {
    if (dayjs(time * 1000).year() === dayjs().year()) {
      return dayjs(time * 1000).format('MM-DD HH:mm')
    }
    return dayjs(time * 1000).format('YY-MM-DD HH:mm')
    // return dayjs(time * 1000).format('YYYY-MM-DD HH:mm')
  }
}

export const timeWithUnit = (val: number) => {
  if (val <= 60) {
    return '1分钟内'
  } else if (val <= 5 * 60) {
    return '5分钟内'
  } else if (val <= 10 * 60) {
    return '10分钟内'
  } else if (val <= 30 * 60) {
    return '30分钟内'
  } else if (val <= 60 * 60) {
    return '1小时内'
  } else if (val <= 2 * 60 * 60) {
    return '2小时内'
  } else if (val <= 6 * 60 * 60) {
    return '6小时内'
  } else if (val <= 12 * 60 * 60) {
    return '12小时内'
  } else if (val <= 24 * 60 * 60) {
    return '24小时内'
  }
  return '24小时后'
}

/**
 *
 * @param val 值
 * @param precision 精度，0:整数，以第1位小数四舍五入，1: 1位小数，以第2位小数四舍五入
 * @returns
 */
export const digitalUnit = (val: any, precision: number = 1) => {
  const num = Number(val)
  if (num <= 9999) return `${num}`
  if (num >= 99999500 && num <= 99999999)
    return `${Number(1).toFixed(precision)}亿`
  if (num > 99999999) {
    return `${round(num / 100000000, precision)}亿`
  } else {
    return `${round(num / 10000, precision)}万`
  }
}

/**
 * 16:9的图片旋转90deg
 */
export const getIsNeedRotate = ({
  size,
  width,
  height,
}: {
  size?: string
  width?: number | null
  height?: number | null
}) => {
  let needRotate = false
  if (size === '16:9') {
    // 1.传入尺寸的话用尺寸进行判断
    needRotate = true
  }
  // 先不进行计算
  // else if (
  //   width &&
  //   height &&
  //   width / height >= 1.6 &&
  //   width / height <= 1.8
  // ) {
  //   // 2.通过计算宽高进行判断
  //   needRotate = true
  // }
  return needRotate
}
export const toRotateImage = ({
  imageUrl,
  size,
  width,
  height,
  rotateDeg = 90,
  w = 800,
  noNeedRotate,
  formateImage,
}: {
  imageUrl: string
  size?: string
  width?: number | null
  height?: number | null
  // 旋转角度
  rotateDeg?: number
  // 设置七牛的图片宽度
  w?: number
  noNeedRotate?: boolean
  formateImage?: (url: string) => string
}) => {
  if (!imageUrl) {
    return ''
  }

  return !noNeedRotate && getIsNeedRotate({ size, width, height })
    ? `${imageUrl}?imageMogr2/rotate/${rotateDeg}/thumbnail/${w}x`
    : !!formateImage
    ? formateImage(imageUrl)
    : imageUrl
}

/** 格式化文件大小 */
export const getFileSize = (fileSize: number = 0) => {
  let size = '0'
  if (fileSize > 0) {
    if (fileSize < 0.1 * 1024) {
      size = fileSize.toFixed(2) + 'B'
    } else if (fileSize < 0.1 * 1024 * 1024) {
      size = (fileSize / 1024).toFixed(2) + 'KB'
    } else if (fileSize < 0.1 * 1024 * 1024 * 1024) {
      size = (fileSize / (1024 * 1024)).toFixed(2) + 'MB'
    } else {
      size = (fileSize / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
    }
  }
  return size
}
/** 时间段 时分秒显示 */
const zero = (num: number) => (num >= 10 ? num : '0' + num)
export const toDurationHms = (duration: any) => {
  if (!duration || duration < 0) {
    return `00:00:00`
  }

  const h = Math.floor(duration / 3600)
  const remain = duration % 3600
  const m = Math.floor(remain / 60)
  const s = remain % 60
  return `${zero(h)}:${zero(m)}:${zero(s)}`
}
