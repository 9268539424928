/** 正则： 手机号 */
export const isMobileReg = /^1\d{10}$/
/** 正则： 邮箱 */
export const isEmailReg =
  /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/

export const isTradePwd = /^\d{6}$/

export const isIdCard =
  /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/

export const isURL = /^https?:\/\//

export const isAmount = /^(\d+)(.\d{0,2})?$/
