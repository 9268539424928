export enum SystemCode {
  CHAIN_SHOP = '1', // 链作商城
  SCHOOL = '2', // 学院
  COPYRIGHT = '3', // 版权
  COVENANT = '5', // 合同
  GOLD_SHOP = '6', // 金币商城
  COPYRIGHT_SHOP = '7', // 版权文创商城
  WUJIE_AI = '8', // 无界AI
}

export enum MallOrderStatus {
  ALL = 'ALL',
  TOBEPAID = '0', //待支付
  INPAID = '1', //支付中
  TOBEEXPRESS = '2', //待发货
  TOBERECEIVED = '3', //带收货
  DONE = '4', //交易完成
  CANCEL = '-1', //交易取消
  REFUND = '-2', //退款关闭
}
