import 'antd-mobile/es/global'
import '~/styles/tailwind.css'
import '~/styles/variables.css'
import '~/styles/nprogress.css'
import '~/styles/global.css'

import type { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from '~/graphqls/apollo/client'
import zhCN from 'antd-mobile/es/locales/zh-CN'
import { ConfigProvider, Toast } from 'antd-mobile'
import { useEffect } from 'react'
import { useInitStore } from '~/stores'
import { Provider as MobXProvider } from 'mobx-react'
import NProgress from 'nprogress'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react'

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps)
  const store = useInitStore(pageProps.initialState)

  const router = useRouter()

  useEffect(() => {
    // 禁用浏览器的默认行为
    history.scrollRestoration = 'manual'

    if (router?.events?.on) {
      router.events.on('routeChangeStart', NProgress.start)
      router.events?.on('routeChangeComplete', url => {
        window._hmt?.push(['_trackPageview', url])
        NProgress.done()
      })
      router.events.on('routeChangeError', NProgress.done)
    }
  }, [router.events])

  Toast.config({ duration: 1000 })

  return (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider locale={zhCN}>
        <MobXProvider store={store}>
          <Component {...pageProps} />
        </MobXProvider>
      </ConfigProvider>
    </ApolloProvider>
  )
}

export default observer(MyApp)
