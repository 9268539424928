/** 化身 */
export const avatar = [
  {
    name: 'createAvatar', // 新建化身
    isMock: false,
  },
  {
    name: 'pollAiAvatarList', // 我的化身轮询false,
    isMock: false,
  },
  {
    name: 'imageCheck', // 图片检测
    isMock: false,
  },
]
