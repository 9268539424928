export const IMAGE_CDN = 'https://webcdn.wujieai.com/shenyin'
export const PAGE_SIZE = 40
export const SEARCH_PAGE_SIZE = 36
/** 无界ai协议缓存前缀key */
export const AGREEMENT_PRFIX_KEY = 'ai.config'

export const GENERATE_NUM = 1 // 写真生成数量
export const CONSUME_INTEGRAL = 2 // 生成写真消耗仙元

export interface ArtworkItemProps {
  url: string
  id: number
}

export const artworkData: ArtworkItemProps[] = [
  {
    url: 'https://cdn.wujiebantu.com/ai/9F3586900790BE44AB8F239182B9FEE6-01.jpg',
    id: 1431289,
  },
  {
    url: 'https://cdn.wujiebantu.com/ai/9EC2C90B264D4AF836A8D090E181AE01-01.jpg',
    id: 1431302,
  },
  {
    url: 'https://cdn.wujiebantu.com/ai/817B51F83E1D214F8226934453C88E3C-01.jpg',
    id: 1431305,
  },
  {
    url: 'https://cdn.wujiebantu.com/ai/2FC4651425DA4CECDC9B012392162C28-01.jpg',
    id: 1431312,
  },
  {
    url: 'https://cdn.wujiebantu.com/ai/ABB78FB3800BFECC1FA1DF60369BD5C8-01.jpg',
    id: 1431315,
  },
  {
    url: 'https://cdn.wujiebantu.com/ai/223390F03EF5EB6D107862EE7A83E2C4-01.jpg',
    id: 1431320,
  },
  {
    url: 'https://cdn.wujiebantu.com/ai/4870154B7D10D2387D48F593E99FE874-01.jpg',
    id: 1431316,
  },
  {
    url: 'https://cdn.wujiebantu.com/ai/B7B9D73F0FF1006B5CEE0B2E82C4F07E-01.jpg',
    id: 1431318,
  },
  {
    url: 'https://cdn.wujiebantu.com/ai/A5627B1FFE02245CBBDD65C31514D648-01.jpg',
    id: 1431290,
  },
  {
    url: 'https://cdn.wujiebantu.com/ai/90BD220F77AC27BF517B4A7E1E55B177-01.jpg',
    id: 1431307,
  },

  {
    url: 'https://cdn.wujiebantu.com/ai/AB423AC9C82BA148E98463DA2926B919-01.jpg',
    id: 1431301,
  },
  {
    url: 'https://cdn.wujiebantu.com/ai/867A9443E0F713884DEF1E8A346EA89F-01.jpg',
    id: 1431314,
  },
]
