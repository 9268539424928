import dayjs from 'dayjs'
dayjs.extend(require('dayjs/plugin/duration'))

// 鉴权
export * from './auth'
// userAgent
export * from './user-agent'
// 正则
export * from './regexp'
// 格式化
export * from './format'
// 文件处理
export * from './file'
// 数据处理
export * from './data'
// 其他
export * from './other'
// 登录
export * from './login'
// 用户访问记录
export * from './scroll-cache'
// hash
export * from './hash'
// localStorage
export * from './localstorage'
