import { mockKeys, mockHost } from '~/configs/mock'

/** 需要mock的operationName集合 */
const mockOperationNames = new Map()
mockKeys.length &&
  mockKeys.forEach(item => {
    if (item.isMock) {
      mockOperationNames.set(item.name, item.isMock)
    }
  })

export { mockOperationNames }

/** 是否开发环境 */
export const isDevelopment = () => {
  return process.env.NEXT_PUBLIC_NODE_ENV === 'development'
}

/**
 * 获取mock: uri
 * @param uri 对应默认的请求 GRAPHQL_HOST
 * @param operationName 请求参数name
 * @returns 如果mock条件成立，则返回mockUri： uri + operationName (方便mock调试)
 */
export const getMockUri = (uri: string | undefined, operationName: string) => {
  let mockUri = uri
  /** mock成立条件：
   * 1.对应的operationName存在并且已经开启： isMock 为true
   * 2.开发环境
   */
  if (
    isDevelopment() &&
    operationName &&
    mockOperationNames.get(operationName)
  ) {
    mockUri = mockHost
  }
  return mockUri
}
