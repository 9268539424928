import dayjs from 'dayjs'

export const getGraphData = (data: any[]) => {
  if (!data?.length) return []

  let newData: any[] = []
  data.forEach(item => {
    newData.push(item.node)
  })
  return newData
}
export const getGraphDataWithCursor = (data: any[]) => {
  if (!data?.length) return []

  let newData: any[] = []
  data.forEach(item => {
    if (item.node) {
      item.node.cursor = item.cursor
    }
    newData.push(item.node)
  })
  return newData
}

export const chunkDataAsTimeTarget = (arr: any, target: string) => {
  const len = arr.length
  if (len <= 1) return [arr]
  let data = []
  let child = [arr[0]]
  let dayFormat = dayjs(arr[0][target] * 1000).format('YYYY-MM')
  for (let i = 1; i < len; i++) {
    if (dayFormat === dayjs(arr[i][target] * 1000).format('YYYY-MM')) {
      child.push(arr[i])
    } else {
      data.push(child)
      dayFormat = dayjs(arr[i][target] * 1000).format('YYYY-MM')
      child = [arr[i]]
    }
    if (i === len - 1) {
      data.push(child)
    }
  }
  return data
}

export function uniqueArray(
  prevArr: any[],
  appendArr: any[],
  fn?: (it: any) => any
) {
  if (!appendArr.length) return prevArr
  const ids = prevArr.map(item => {
    return fn ? fn(item) : item['id']
  })
  return [
    ...prevArr,
    ...appendArr.filter(item => {
      return !ids.includes(fn ? fn(item) : item['id'])
    }),
  ]
}
export function uniqueArrayUnShift(
  prevArr: any[],
  appendArr: any[],
  fn?: (it: any) => any
) {
  if (!appendArr.length) return prevArr
  const ids = prevArr.map(item => {
    return fn ? fn(item) : item['id']
  })
  return [
    ...appendArr.filter(item => {
      return !ids.includes(fn ? fn(item) : item['id'])
    }),
    ...prevArr,
  ]
}

// 新的去重，似乎比上面的优雅
export function uniqueFunc(arr: any[], key: string) {
  const res = new Map()
  return arr.filter(item => !res.has(item[key]) && res.set(item[key], 1))
}

//根据某个值，组成二维数组
export const groupListById = (data: any[], fn: (it: any) => any) => {
  const groups: any = new Map()
  data.forEach(it => {
    const groupId = fn(it)
    groups.set(groupId, groups.get(groupId) || [])
    groups.get(groupId).push(it)
  })
  return [...groups.values()]
}

// 根据时间预热等获取活动状态
// -1_预热 -2_未开始 0_进行中 1_已结束
export const getActivityStatus = ({
  startTime,
  endTime,
  warmUpHour,
}: {
  // 开始时间
  startTime: number
  // 结束时间
  endTime: number
  // 预热时间
  warmUpHour?: number
}) => {
  const now = dayjs().unix()
  // 1.未开始
  if (now < startTime) {
    // 预热
    if (warmUpHour && warmUpHour * 60 * 60 + now > startTime) {
      return -1
    }
    // 未开始
    return -2
  }
  // 2.进行中
  if (now > startTime && now < endTime) {
    return 0
  }
  // 3.已结束
  return 1
}

// 深拷贝
export const deepCopy = function (obj: any) {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * 自定义规则排序
 * @param sortArr 需要排序的数组
 * @param baseArr 按照什么顺序排序,不在baseArr里的字段会被过滤
 * @param fn 排序的字段
 */
export const sortBy = <T = any>(
  sortArr: T[],
  baseArr: any[],
  fn: (it: any) => any
) => {
  if (sortArr?.length <= 1) return sortArr
  return sortArr
    .sort((a: T, b: T) => baseArr.indexOf(fn(a)) - baseArr.indexOf(fn(b)))
    ?.filter((it: T) => baseArr.includes(fn(it)))
}

/** 判断 a 是否是 null 或者 undefined, 排除 a 为 0 的情况 */
export function isNullOrUndefined(a: any): Boolean {
  if (a === null) {
    return true
  }
  if (a === undefined) {
    return true
  }

  return false
}
