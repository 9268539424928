export function randomRange(min: number, max: number) {
  let returnStr = '',
    range = max ? Math.round(Math.random() * (max - min)) + min : min,
    arr = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ]
  for (let i = 0; i < range; i++) {
    let index = Math.round(Math.random() * (arr.length - 1))
    returnStr += arr[index]
  }
  return returnStr
}

// 获取cookie对象
export function getCookies(cookie: string) {
  const cookies: Record<string, string> = {}
  ;(cookie || '').split(';').map((item: string) => {
    item = item.replace(/'/g, '').replace(/ /g, '')
    const key = item.split('=')[0]
    const value = item.split('=')[1]
    cookies[key] = value
  })
  return cookies
}

// graphql错误信息处理
export const graphQLErrorMessage = (error: any) => {
  if (error?.message === 'Failed to fetch') {
    return '网络错误，请检查网络连接后重试...'
  }
  // 跟后端约定：code末尾为0000代表可使用接口给的message，否则用前端自定义的兜底message
  if (Number(error?.graphQLErrors?.[0]?.extensions?.code) % 10000 === 0) {
    return (
      error?.graphQLErrors?.[0]?.extensions?.message ||
      error?.graphQLErrors?.[0].message
    )
  }
  return ''
}

export const graphQLErrorCode = (error: any) => {
  return Number(error?.graphQLErrors?.[0]?.extensions?.code || -1)
}

export const isNotFound = (error: any) => {
  return Number(error?.graphQLErrors?.[0]?.extensions?.code) === 20020008
}

/**
 * 预加载图片资源
 * @param imgs 图片url列表
 * @returns
 */
export function preLoadImg(imgs: string[]) {
  imgs = imgs?.filter(it => !!it)
  if (!imgs.length) return Promise.reject()
  let count = 1
  return new Promise((resolve, reject) => {
    for (let i = 0; i < imgs.length; i++) {
      let img = new Image()
      img.src = imgs[i]
      img.onload = function () {
        count++
        if (count === imgs.length) {
          resolve(true)
        } else {
          resolve(false)
        }
      }
    }
  })
}

/**
 * 加载单个图片资源
 * @param imgs 图片url
 * @returns
 */
export function onLoadImg(url: string) {
  return new Promise((resolve: (img: HTMLImageElement) => void, reject) => {
    let img = new Image()
    img.src = url
    img.onload = function () {
      resolve(img)
    }
    img.onerror = function () {
      reject(null)
    }
  })
}

export const urlReplace = (href: string) => {
  if (href && /^#|javasc/.test(href) === false) {
    if (history.replaceState) {
      history.replaceState(null, document.title, href.split('#')[0] + '#')
      location.replace('')
    } else {
      location.replace(href)
    }
  }
}

export const setMallImgLazyoad = (html: string) => {
  let imgReg = /<img.*?(?:>|\/>)/gi
  let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
  let arr = html.match(imgReg)
  if (!arr || !arr.length) return html
  for (let i = 0; i < arr.length; i++) {
    let srcs = arr[i].match(srcReg)
    if (srcs && srcs[1]) {
      html = html.replace(
        arr[i],
        `<img src="${srcs[1]}" key="key_${i}" loading="lazy">`
      )
    }
  }
  return html
}

export const getMallLimitText = (value: number | null | undefined) => {
  if (!value) return
  const texts = ['', '超过商品限购数量', '销售库存不足']
  return texts[value]
}

export const changeURLArg = (
  url: string,
  arg: string,
  arg_val: string | number
) => {
  let pattern = arg + '=([^&]*)'
  let replaceText = arg + '=' + arg_val
  if (url.match(pattern)) {
    let tmp = '/(' + arg + '=)([^&]*)/gi'
    tmp = url.replace(eval(tmp), replaceText)
    return tmp
  } else {
    if (url.match('[?]')) {
      return url + '&' + replaceText
    } else {
      return url + '?' + replaceText
    }
  }
}

export const setLocalStorage = (data: any, target: string) => {
  window.localStorage.setItem(target, JSON.stringify(data))
}

export const removeLocalStorage = (target: string) => {
  window.localStorage.removeItem(target)
}

export const getLocalStorage = (target: string, defaultValue?: any) => {
  try {
    return JSON.parse(window.localStorage.getItem(target) || `${defaultValue}`)
  } catch (err) {
    return defaultValue
  }
}

export const isPromise = (obj: unknown): obj is Promise<unknown> => {
  return (
    !!obj && typeof obj === 'object' && typeof (obj as any).then === 'function'
  )
}

export const compareVersion = (v1: string, v2: string) => {
  if (v1 === v2) {
    return 0
  }

  const vs1 = v1.split('.').map(a => parseInt(a))
  const vs2 = v2.split('.').map(a => parseInt(a))

  const length = Math.min(vs1.length, vs2.length)
  for (let i = 0; i < length; i++) {
    if (vs1[i] > vs2[i]) {
      return 1
    } else if (vs1[i] < vs2[i]) {
      return -1
    }
  }

  if (length === vs1.length) {
    return -1
  } else {
    return 1
  }
}

/**服务端统一错误处理 */
export const serverSideCatch = async (fn: () => Promise<any>) => {
  try {
    return await fn()
  } catch (err) {
    if (isNotFound(err)) {
      return {
        notFound: true,
      }
    } else {
      throw err
    }
  }
}

/** 百度事件分析手动埋点 */
export const trackEvent = ({
  category,
  label,
  event,
  id,
}: {
  category: string
  label: string
  event?: string
  id?: string
}) => {
  if (typeof window !== 'undefined' && window._hmt) {
    window._hmt.push([
      '_trackEvent',
      category,
      event || '点击',
      label,
      '',
      id || 1,
    ])
  }
}
